<template>
    <div class="chekOutBox" v-if="carts && carts.products > 0">
        <div class="main site-content site-mover">
            <!-- 左边-->
            <div class="container-column container-column-main">
                <div class="navigation-steps-title">Proceed with the order</div>
                <ol class="opc-navigation navigation-steps">
                    <li class="opc-navigation-item checkmail recap active">
                        <span>Shipping Details</span>
                    </li>
                    <!--            <li class="opc-navigation-item checkmail recap">-->
                    <!--              <span>2.Shipping</span>-->
                    <!--            </li>-->
                    <!--            <li class="opc-navigation-item checkmail recap">-->
                    <!--              <span>3.Payment</span>-->
                    <!--            </li>-->
                </ol>
                <!-- 收货地址-->
                <div class="containerListBox">
                    <div class="addressBox">
                        <div class="account-address-book account-section">
                            <div class="smartphone-back-button">
                                <router-link to>
                                    <span class="icon"></span>
                                    <span @click="goBack">Back</span>
                                </router-link>
                                <!--                <ul class="backUl">-->
                                <!--                  <li class="backicon">-->
                                <!--                    <button @click="goBack"  style="background-color: #fff;color: #212121;padding: 0;text-align: left;position: relative;margin-top: 0;z-index:100;pointer-events: auto;">-->
                                <!--                      <span class="back-icon"></span>-->
                                <!--                      <span  style="position: relative;left:2rem">Back</span>-->
                                <!--                    </button>-->
                                <!--                  </li>-->
                                <!--                </ul>-->

                            </div>
                            <div>
                                <div
                                    v-if="address"
                                    role="row"
                                    class="addressEntry is-billing-default country-gw"
                                >
                                    <div role="rowheader" class="address-wrapper">
                                        <div class="name">
                                            <!--firstname-->
                                            <span class="value" data-sensitive="true">{{
                                                    address.consignee
                                                }}</span>
                                            <!--lastname-->
                                        </div>
                                        <div class="streetWithNumber">
                                            <!--address-->
                                            <span class="value" data-sensitive="true">{{
                                                    address.street
                                                }}</span>
                                        </div>
                                        <div class="city">
                                            <!--city-->
                                            <span class="value" data-sensitive="true">{{
                                                    address.province
                                                }}</span>
                                            <!--              <span class="value" data-sensitive="true">XXXXX </span>-->
                                            <!--province-->
                                            <span class="value" data-sensitive="true">{{
                                                    address.city
                                                }}</span>
                                            <!--country-->
                                            <!--              <span class="value">GW </span>-->
                                            <!--              <a href="#"><span class="messageInfo">info</span></a>-->
                                        </div>
                                        <div class="mail">
                      <span class="value" data-sensitive="true">{{
                              address.email
                          }}</span>
                                        </div>
                                        <div class="phone">
                      <span class="value" data-sensitive="true">{{
                              address.mobile
                          }}</span>
                                        </div>
                                        <div class="mobile-num">
                      <span class="value" data-sensitive="true">{{
                              address.tel
                          }}</span>
                                        </div>
                                    </div>
                                    <div role="cell">
                    <span class="messageInfo" @click="modifyAddress(address)"
                    >Modify</span
                    >
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="!address"
                                class="account-address-book account-section-full-width"
                            >
                                <button @click="addDrawer">Add a new address</button>
                            </div>

                            <div
                                v-if="address"
                                class="account-address-book account-section-full-width"
                            >
                                <form :action="paymentApiUrl" method="POST">
                                    <input type="hidden" name="orderId" v-model="orderId"/>
                                    <input
                                        type="hidden"
                                        name="userId"
                                        :value="this.$store.getters.userInfo.id"
                                    />
                                    <input
                                        id="paymentData"
                                        type="hidden"
                                        name="stripeToken"
                                        value=""
                                    />
                                    <noscript
                                    >You must
                                        <a href="http://www.enable-javascript.com" target="_blank"
                                        >enable JavaScript</a
                                        >
                                        in your web browser in order to pay via Stripe.
                                    </noscript>
                                    <el-button
                                        v-if="Object.keys(carts.list).length"
                                        :loading="paymentBtnLoading"
                                        :disabled="paymentBtnDisable"
                                        @click="checkout"
                                        id="paymentBtn"
                                        class="nextPageButton"
                                        value="Proceed to checkout"
                                        :data-key="publishableKey"
                                        :data-amount="carts.subTotal"
                                        data-name="BING XU"
                                        data-description="Bingxu Collection"
                                        data-image=""
                                        data-locale="auto"
                                        data-billing-address="false"
                                        data-zip-code="false"
                                    >
                                        <span class="icon"></span>
                                        <span class="text">Proceed to checkout</span>
                                    </el-button>
                                </form>
                            </div>
                        </div>
                        <el-drawer
                            :modal="false"
                            :visible.sync="newAddress"
                            size="100%"
                            direction="btt"
                        >
                            <div class="addressDraer">
                                <div class="mfp-container mfp-inline-holder">
                                    <div class="mfp-content">
                                        <div class="inner mfp-content-scrollable-area">
                                            <div class="mfp-content-flex-item">
                                                <div class="mfp-content-holder">
                                                    <el-form
                                                        ref="registerForm"
                                                        :rules="addressRules"
                                                        :label-position="labelPosition"
                                                        :model="registerForm"
                                                        class="demo-ruleForm"
                                                        size="small"
                                                        :hide-required-asterisk="true"
                                                    >
                                                        <h2
                                                            class="layer-title"
                                                            v-show="addressStatus === 0"
                                                        >
                                                            New address
                                                        </h2>
                                                        <h2
                                                            class="layer-title"
                                                            v-show="addressStatus === 1"
                                                        >
                                                            Modify this address
                                                        </h2>
                                                        <div class="mandatory">* Required fields</div>
                                                        <div class="serverSideErrors"></div>
                                                        <div class="Name">
                                                            <el-form-item
                                                                label="First Name *"
                                                                prop="firstName"
                                                            >
                                                                <el-input
                                                                    v-model="registerForm.firstName"
                                                                    placeholder="First Name"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="Surname">
                                                            <el-form-item label="Last Name *" prop="lastName">
                                                                <el-input
                                                                    v-model="registerForm.lastName"
                                                                    placeholder="Last Name"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="Address">
                                                            <el-form-item label="Address *" prop="street">
                                                                <el-input
                                                                    v-model="registerForm.street"
                                                                    placeholder="Address"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="CareOf">
                                                            <el-form-item label="C/O " prop="co">
                                                                <el-input
                                                                    v-model="registerForm.co"
                                                                    placeholder="C/O"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="City">
                                                            <el-form-item label="City *" prop="city">
                                                                <el-input
                                                                    v-model="registerForm.city"
                                                                    placeholder="City"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="Province">
                                                            <el-form-item
                                                                label="State/Province *"
                                                                prop="province"
                                                            >
                                                                <el-input
                                                                    v-model="registerForm.province"
                                                                    placeholder="State/Province"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="countryField">
                                                            <el-form-item
                                                                label="Country/Region"
                                                                prop="country"
                                                            >
                                                                <el-input
                                                                    v-model="registerForm.country"
                                                                    placeholder="Country/Region"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="Mail">
                                                            <el-form-item
                                                                label="Email address *"
                                                                prop="email"
                                                            >
                                                                <el-input
                                                                    v-model="registerForm.email"
                                                                    placeholder="Email address"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="Phone">
                                                            <el-form-item
                                                                label="Phone Number *"
                                                                prop="mobile"
                                                            >
                                                                <el-input
                                                                    v-model="registerForm.mobile"
                                                                    placeholder="Phone Number"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="Mobile">
                                                            <el-form-item label="Cell phone " prop="tel">
                                                                <el-input
                                                                    v-model="registerForm.tel"
                                                                    placeholder="Cell phone"
                                                                ></el-input>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="IsDefault">
                                                            <el-checkbox
                                                                :checked="registerForm.isDefaultShippingAddress"
                                                                v-model="registerForm.isDefaultShippingAddress"
                                                            >Default shipping address
                                                            </el-checkbox>
                                                        </div>
                                                        <!--                    <div class="IsBillingDefault">-->
                                                        <!--                      <el-checkbox v-model="registerForm.isDefaultBillAddress"-->
                                                        <!--                        >Default billing address</el-checkbox-->
                                                        <!--                      >-->
                                                        <!--                    </div>-->
                                                        <p class="privacyDisclaimer">
                                                            The personal data you have entered will be used by
                                                            BING XU to expedite the purchasing process.
                                                            <br/>Please note that you are responsible for
                                                            third party data that you disclose to BING XU<br/>
                                                            Your data is safe with BING XU.<br/>Read the
                                                            <router-link target="_blank" :to="{path: '/privacy'}" >Privacy Policy</router-link> for further
                                                            information.
                                                        </p>
                                                        <div class="buttonsRow">
                                                            <button
                                                                type="button"
                                                                class="submit"
                                                                @click="save('registerForm')"
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class="closeBtn"
                                                                @click="cancel"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </el-form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-drawer>
                        <el-drawer
                            :modal="false"
                            :visible.sync="addAddress"
                            size="80%"
                            direction="btt"
                        >
                            <div>Your address has been successfully saved</div>
                        </el-drawer>
                    </div>
                </div>
            </div>
            <!-- 右边 -->
            <div
                class="container-column container-column-aside checkout-recap"
                v-if="carts && Object.keys(carts).length"
            >
                <div class="checkout-sidebar checkout-recap-content">
                    <div class="items-recap">
                        <header class="items-recap-header">
                            <div class="items-recap-title-wrapper">
                                <h2 class="items-recap-title">
                  <span>Shopping Bag</span
                  ><span>({{ carts.productQuantity }})</span>
                                </h2>
                            </div>
                            <!--                <div class="edit-button-wrapper">-->
                            <!--                  <a href="#">Modify</a>-->
                            <!--                </div>-->
                        </header>
                        <div class="orderItemsRecap">
                            <ul class="shopping-bag-list">
                                <div
                                    v-for="(item, index) in carts.list"
                                    class="shopping-bag-list-product"
                                >
                                    <div class="shopping-bag-element">
                                        <div class="shopping-bag-element-image">
                                            <img :src="item.productThumbnail"/>
                                        </div>
                                        <div class="shopping-bag-element-info">
                                            <div class="element-info element-title">
                                                <span>Luka Shirt in Cotton</span>
                                            </div>
                                            <div class="lement-price">
                                                HK$ {{ fenToYuan(item.amount) }}
                                            </div>
                                            <div v-if="item.pack">
                                                <span>Pack: {{ item.pack.name }}</span>
                                            </div>
                                            <div class="element-info element-size-color">
                                                <div
                                                    :key="k"
                                                    v-for="(sku, k) in item.productSku"
                                                    class="element-size"
                                                >
                                                    {{ k }}: {{ sku }}
                                                </div>
                                                <div class="element-quantity">
                                                    Quantity: {{ item.quantity }}
                                                </div>
                                            </div>
                                            <!--                         <div class="element-alert">Limited availability</div>-->
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <div class="orderPricesRecap priceRecap">
                            <ul class="price-recap-list">
                                <li>
                                    <div class="salesTaxDetails">
                                        <span class="label">Subtotal</span>
                                        <div>
                      <span class="valueToPay"
                      >HK$ {{ fenToYuan(carts.subTotal) }}</span
                      >
                                        </div>
                                    </div>
                                </li>
                                <!--                  <li>-->
                                <!--                    <h3 class="price-recap-title">Gift Wrapping</h3>-->
                                <!--                  </li>-->
                                <li class="price-recap-shipping">
                                    <h3 class="price-recap-title">Shipping</h3>
                                    <div class="price-recap-value">Express（Complimentary）</div>
                                </li>
                                <li class="price-recap-payment">
                                    <h3 class="price-recap-title">Payment</h3>
                                    <div class="price-recap-value">Credit Card</div>
                                </li>
                                <li class="price-recap-total">
                                    <div class="total-price">
                                        <span class="label">Total</span>
                                        <div class="price">HK$ {{ fenToYuan(carts.subTotal) }}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="sidebar-checkout-info">
                            <ul class="opc-info-wrapper">
                                <li class="opc-info">
                                    <h3 class="opc-info-title">Easy returns</h3>
                                    <!--                  <p>-->
                                    <!--                    We provide an easy return service: just ship your items back-->
                                    <!--                    to us with a shipping service of your choice within 30 days-->
                                    <!--                    of delivery.<br />For further information, visit the-->
                                    <!--                    <a href="#">Customer Care</a> area.-->
                                    <!--                  </p>-->
                                    <div class="agreeCheckBox">
                                        <el-checkbox
                                            @change="agreePurchaseAgreement"
                                            v-model="isAgreePurchaseAgreement"
                                            class="agreeCheck"
                                        >
                                        </el-checkbox>
                                        <span class="agreeItem">
                      I have read and agree《
                      <span class="agreeItem-items"
                            @click="agreeItemDrawer">BingXu series product purchase agreement</span>
                      》
                    </span>
                                    </div>

                                </li>
                                <li class="opc-info info-secure-payment">
                                    <h3 class="opc-info-title">Secure payments</h3>
                                    <p>
                                        Payment information is transferred according to the highest
                                        security standards, guaranteed by Geotrust and Trustwave:
                                        your credit card details will be completely encrypted.
                                    </p>
                                    <button class="trustwave">
                                        <img
                                            src="https://sealserver.trustwave.com/seal_image.php?customerId=&size=105x54&style=normal"
                                        />
                                    </button>
                                </li>
                                <li class="opc-info info-pay-by">
                                    <h3 class="opc-info-title">You can pay by</h3>
                                    <div>
                                        <ul class="paymentMethods">
                                            <li class="type_1">
                                                <img
                                                    src="https://www.therow.com/ytos/resources/THEROW/svg/cards/credit_cards_color.svg"
                                                />
                                            </li>
                                            <!--                        <li class="type_300">-->
                                            <!--                          <img src="https://www.therow.com/ytos/resources/THEROW/svg/cards/paypal.svg">-->
                                            <!--                        </li>-->
                                        </ul>
                                    </div>
                                </li>
                                <li class="opc-info info-customer-care">
                                    <h3 class="opc-info-title">Customer care</h3>
                                    <p>
                                        We remain at your disposal for any questions:<a href="#"
                                    >contact us</a
                                    >
                                    </p>
                                </li>
                                <li class="opc-info info-view-bag-care">
                                    <div class="view-bag" @click="addToCart">View Bag</div>
                                    <span>
                    <span class="label">Total:</span>
                    <span class="price"
                    >HK$ {{ fenToYuan(carts.subTotal) }}</span
                    ></span
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <el-drawer
                    :modal="false"
                    :visible.sync="shoppingCartItem"
                    size="100%"
                    direction="btt"
                >
                    <!--        <h2 class="sidebar-title">Filters</h2>-->
                    <div class="checkout-sidebar checkout-recap-content">
                        <div class="items-recap">
                            <header class="items-recap-header shop-cart">
                                <div class="items-recap-title-wrapper">
                                    <h2 class="items-recap-title">
                    <span>Shopping Bag</span
                    ><span>({{ carts.productQuantity }})</span>
                                    </h2>
                                </div>
                                <!--                <div class="edit-button-wrapper">-->
                                <!--                  <a href="#">Modify</a>-->
                                <!--                </div>-->
                            </header>
                            <div class="orderItemsRecap shop-cart">
                                <ul class="shopping-bag-list">
                                    <div
                                        v-for="(item, index) in carts.list"
                                        class="shopping-bag-list-product"
                                    >
                                        <div class="shopping-bag-element">
                                            <div class="shopping-bag-element-image">
                                                <img :src="item.productThumbnail"/>
                                            </div>
                                            <div class="shopping-bag-element-info">
                                                <div class="element-info element-title">
                                                    <span>Luka Shirt in Cotton</span>
                                                </div>
                                                <div class="lement-price">
                                                    HK$ {{ fenToYuan(item.amount) }}
                                                </div>
                                                <div class="element-info element-size-color">
                                                    <div
                                                        :key="k"
                                                        v-for="(sku, k) in item.productSku"
                                                        class="element-size"
                                                    >
                                                        {{ k }}: {{ sku }}
                                                    </div>
                                                    <div class="element-quantity">
                                                        Quantity: {{ item.quantity }}
                                                    </div>
                                                </div>
                                                <!--                         <div class="element-alert">Limited availability</div>-->
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div class="orderPricesRecap priceRecap shop-cart">
                                <ul class="price-recap-list">
                                    <li>
                                        <div class="salesTaxDetails">
                                            <span class="label">Subtotal</span>
                                            <div>
                        <span class="valueToPay"
                        >HK$ {{ fenToYuan(carts.subTotal) }}</span
                        >
                                            </div>
                                        </div>
                                    </li>
                                    <!--                  <li>-->
                                    <!--                    <h3 class="price-recap-title">Gift Wrapping</h3>-->
                                    <!--                  </li>-->
                                    <li class="price-recap-shipping">
                                        <h3 class="price-recap-title">Shipping</h3>
                                        <div class="price-recap-value">
                                            Express（Complimentary）
                                        </div>
                                    </li>
                                    <li class="price-recap-payment">
                                        <h3 class="price-recap-title">Payment</h3>
                                        <div class="price-recap-value">Credit Card</div>
                                    </li>
                                    <li class="price-recap-total">
                                        <div class="total-price">
                                            <span class="label">Total</span>
                                            <div class="price">
                                                HK$ {{ fenToYuan(carts.subTotal) }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="sidebar-checkout-info">
                                <ul class="opc-info-wrapper">
                                    <li class="opc-info info-view-bag-care">
                                        <div class="view-bag" @click="shoppingCart">View Bag</div>
                                        <span>
                      <span class="label">Total:</span>
                      <span class="price"
                      >HK$ {{ fenToYuan(carts.subTotal) }}</span
                      ></span
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </el-drawer>
            </div>
            <div>
                <el-drawer
                    title="Notice before purchase"
                    :visible.sync="agreeDrawer"
                    :modal="false"
                    direction="btt"
                    size="100%"
                    class="agreeItemDrawer">
                    <div class="agree-items-text"></div>
                    <p class="agreeText">
                        Bing Xu is a customized brand of independent workshop. It is made according to the order. After
                        the order is placed, no return without reason is provided. Please know before purchasing.
                    </p>
                    <p class="agreeText">
                        After the order is placed successfully, if the product is returned forcibly, 50% of the order
                        amount shall be paid for the cost.
                    </p>
                    <p class="agreeText">
                        The production capacity of Bing Xu independent customization workshop is limited. All orders
                        will be valid for 5-10 minutes after the auction. Overtime orders will be cancelled.
                    </p>
                </el-drawer>
            </div>
        </div>
    </div>
</template>
<script>
import {priceTransform} from "@/utils/util";
import {addressAddSave, clearCart, createOrder, fetchUserDefaultAddress} from "@/api/api";
import $ from "jquery";
import {EventBus} from "../utils/event-bus";

export default {
    name: "checkout",
    data() {
        return {
            isAgreePurchaseAgreement: false,
            agreeDrawer: false,
            selectedAttribute1: null,
            selectedAttribute2: null,
            shoppingCartItem: false,
            carts: [],
            paymentApiUrl: process.env.VUE_APP_BASE_API + "/web-site/payment",
            orderId: null,
            publishableKey:
                "pk_live_51IiFWrKrFCJwUtPru600kTq7kpifA02my6Od3scmL7FKNyxnoahfwTjw1ye99ejYZTa7B7eH9eJOyvUq62RuNXDW00Kz5JSzLu",
            paymentBtnLoading: false,
            paymentBtnDisable: false,
            currentDeleteId: null,
            address: null,
            newAddress: false,
            addAddress: false,
            deleteAddress: false,
            labelPosition: "top",
            checkList1: "",
            checkList2: "",
            value: "",
            addressStatus: 0,
            deleteStatus: 0,
            addressRules: {
                firstName: [
                    {
                        required: true,
                        message: "The name field is required",
                        trigger: "blur"
                    }
                ],
                lastName: [
                    {
                        required: true,
                        message: "The last name field is required",
                        trigger: "blur"
                    }
                ],
                street: [
                    {
                        required: true,
                        message: "The address field is required",
                        trigger: "blur"
                    }
                ],
                city: [
                    {
                        required: true,
                        message: "The city field is required",
                        trigger: "blur"
                    }
                ],
                province: [
                    {
                        required: true,
                        message: "The province field is required",
                        trigger: "blur"
                    }
                ],
                email: [
                    {
                        required: true,
                        message: "The email field is required",
                        trigger: "blur"
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: "The telephone number field is required",
                        trigger: "blur"
                    }
                ]
            },
            registerForm: {
                firstName: "",
                lastName: "",
                email: "",
                province: "",
                city: "",
                county: "",
                street: "",
                mobile: "",
                tel: "",
                country: "",
                co: "",
                isDefaultShippingAddress: false,
                isDefaultBillAddress: false
            }
        };
    },
    mounted() {
        this.myCarts();
        this.fetchAddress();
    },
    methods: {
        agreePurchaseAgreement(e) {
          this.isAgreePurchaseAgreement = e;
        },
        agreeItemDrawer() {
            this.agreeDrawer = true;
        },
        async addToCart() {
            EventBus.$emit("openPCShoppingBagEvent");
            EventBus.$emit("openH5ShoppingBagEvent");
        },
        shoppingCart() {
            this.shoppingCartItem = true;
        },
        fenToYuan(fen) {
            return priceTransform(fen);
        },
        async myCarts() {
            this.$store
                .dispatch("cart/getData")
                .then(data => {
                    this.carts = data;
                    // console.log('购物车数据：', f(this.carts))
                })
                .catch(err => {
                    console.log(err);
                });
        },
        async fetchAddress() {
            const res = await fetchUserDefaultAddress();
            if (res.code === 0) {
                this.address = res.data.detail;
            }
        },
        addDrawer() {
            //添加新地址时将表单内容重置成默认
            this.registerForm = {
                firstName: "",
                lastName: "",
                email: "",
                province: "",
                city: "",
                county: "",
                street: "",
                mobile: "",
                tel: "",
                country: "",
                co: "",
                isDefaultShippingAddress: false,
                isDefaultBillAddress: false,
            };
            this.addressStatus = 0;
            this.newAddress = true;
        },
        modifyAddress(item) {
            //编辑地址时，将表单内容填充为当前修改的条目
            this.registerForm = item;
            this.addressStatus = 1;
            this.newAddress = true;
        },
        async checkout(event) {
            if (!this.isAgreePurchaseAgreement) {
                this.$message({
                    showClose: true,
                    message: 'Please check the Bing Xu Purchase Agreement',
                    type: "error"
                });
                return;
            }
            this.paymentBtnDisable = true;
            this.paymentBtnLoading = true;

            if (!this.orderId) {
                const order = {};
                const products = [];

                this.carts.list.map(item => {
                    products.push({
                        productStockId: item.productStockId,
                        productId: item.productId,
                        productName: item.productName,
                        productThumbnail: item.productThumbnail,
                        productSku: item.productSku,
                        quantity: item.quantity,
                        price: item.price,
                        pack: item.pack
                    });
                });

                order.products = products;
                order.totalFee = this.carts.subTotal;
                order.productFee = this.carts.subTotal;
                order.shippingFee = 0;
                order.consignee = this.address.consignee;
                order.mobile = this.address.mobile;
                order.province = this.address.province;
                order.city = this.address.city;
                order.county = this.address.county;
                order.street = this.address.street;

                console.log("下单数据：", order);
                const response = await createOrder({order});
                console.log("下单结果：", response);

                if (response.code === 0) {
                    this.orderId = response.data.id;
                    clearCart();

                } else {
                    this.paymentBtnDisable = false;
                    this.paymentBtnLoading = false;
                    this.$message({
                        showClose: true,
                        message: response.message,
                        type: "error"
                    });
                }
            }

            if (this.orderId) {
                event.preventDefault();
                let $button = $("#paymentBtn"),
                    $form = $button.parents("form");

                let opts = $.extend({}, $button.data(), {
                    token: function (result) {
                        $form
                            .append(
                                $("#paymentData").attr({
                                    type: "hidden",
                                    name: "stripeToken",
                                    value: result.id
                                })
                            )
                            .submit();
                    }
                });

                StripeCheckout.open(opts);

                setTimeout(() => {
                    this.paymentBtnDisable = false;
                    this.paymentBtnLoading = false;
                }, 3000);
            }
        },
        save(formName) {
            this.$refs[formName].validate(async valid => {
                if (valid) {
                    const res = await addressAddSave({address: this.registerForm});
                    const {code, message} = res;
                    if (code !== 0) {
                        console.log(message);
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.newAddress = false;
                        this.$message({
                            showClose: true,
                            message: "Success",
                            type: "success"
                        });
                    }
                    this.fetchAddress();
                    console.log(res);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        cancel() {
            this.newAddress = false;
        },
        goBack() {
            this.$router.back();
        }
    }
};
</script>
<style scoped>
/deep/ .agreeCheck.el-checkbox {
    padding-left: .5rem;
    padding-right: 0
}

/deep/ .el-button {
    border-radius: 0;
}
</style>
<style scoped lang="scss">
::v-deep .agreeItemDrawer .el-drawer__header > :first-child {
    color: #212121;
    margin-top: 10rem;
    margin-left: 5rem;
}

::v-deep .agreeItemDrawer .el-drawer__close-btn {
    margin-top: -10rem;
}

.agreeItemDrawer {
    .agreeText {
        color: #696969;
        margin: 0 8rem 2rem 8rem;
    }
}

.agreeCheckBox {
    display: flex;
    align-items: center;

    .agreeItem {
        word-break: break-word;
        color: #696969;
        margin-left: .5rem;

        .agreeItem-items {
            cursor: pointer;
            border-bottom: 1px solid #212121;
        }
    }
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    border-radius: 0;
    letter-spacing: inherit;
    padding: 0;
    color: inherit;
}

li {
    list-style: none;
}

h1,
h2,
h3,
h4 {
    font-weight: 400;
    font-size: 1em;
    margin: 0;
}

.header-sticky-native {
    display: none !important;
}

.footer {
    display: none !important;
}

.opc-navigation {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style-position: inside;
    list-style-type: decimal;
    margin: 0 auto;
    max-width: 66.5rem;
    padding: 1.1875rem;
    width: 100%;

    .opc-navigation-item {
        color: #797768;
        position: relative;
    }

    .active {
        color: #212121;
    }

    .active:after {
        background: #212121;
    }
}

.opc-navigation-item:after {
    display: block;
    width: 100%;
    height: 0.125rem;
    background: transparent;
    position: absolute;
    bottom: -1px;
    content: "";
}

.stepWrapper {
    border-top: 1px solid #e1e0de;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #797768;
    position: relative;

    .step-header {
        margin-bottom: 0;
        padding-right: 6.25rem;
        color: #797768;
    }

    .step-header-title {
        border-bottom: 1px dotted transparent;
        display: inline-block;
    }

    .callToAction {
        color: #212121;
        width: auto;
        position: absolute;
    }

    .step-header-title {
        border-bottom: 1px dotted transparent;
        display: inline-block;
    }
}

.items-recap-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 1rem;
    margin-bottom: 1.9375rem;
    border-bottom: 1px solid #e1e0de;
    justify-content: space-between;
    align-items: center;

    .items-recap-title-wrapper {
        display: flex;
    }
}

.orderItemsRecap {
    border-bottom: 1px solid #e1e0de;
}

.shopping-bag-element {
    align-items: flex-end;
    display: flex;
    position: relative;
}

.opc-info-wrapper {
    margin: 0 auto;
    max-width: 66.5rem;
    padding: 1.1875rem;
    width: 100%;
    z-index: 200;
}

.opc-info-title {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
}

.trustwave {
    margin-top: -0.5rem;
    width: auto;
    position: relative;

    img {
        height: 1.25rem;
        width: auto;
    }
}

.info-secure-payment {
    p {
        margin-top: 1rem;
    }
}

.paymentMethods {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;

    li {
        padding: 0;
    }

    .info-pay-by {
        img {
            height: 1.25rem;
            padding-right: 0.25rem;
        }
    }

    .type_300 img {
        height: 1rem;
    }
}

.opc-info-title {
    margin-bottom: 1rem !important;
}

.opc-info {
    a {
        padding-bottom: 2px;
        cursor: pointer;
        border-bottom: 1px solid currentColor;
    }
}

@media (min-width: 1280px) {
    .opc-info.info-view-bag-care {
        display: none;
    }
    .site-content {
        display: flex;
        //flex-direction: row;
        margin: 0 auto;
        max-width: 66.5rem;
        padding-left: 1.1875rem;
        padding-right: 1.1875rem;
        width: 100%;
        flex-grow: 1;
        min-height: auto;
        margin-bottom: 5.625rem;

        .container-column-main {
            width: 38.5rem;
            margin-right: 6.875rem;

            .navigation-steps-title {
                display: block;
                margin-bottom: 1.3125rem;
            }

            .navigation-steps {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0;
                padding: 0;
                border-bottom: 1px solid #e1e0de;
                max-width: 38.5rem;
            }

            .opc-navigation-item {
                padding-bottom: 1rem;
            }

            .containerListBox {
                margin-top: 1rem;
            }

            .step-wrapper-content {
                padding-right: 6.25rem;
            }
        }

        .countryWarning {
            width: 25rem;
            margin-bottom: 1rem;

            .btn {
                width: auto;
            }
        }

        .countryWarning {
            padding: 0;
        }
    }
    //右边内容
    .checkout-recap {
        padding-top: 1.25rem;
        width: 18.75rem;
        margin-top: 0.4375rem;

        .checkout-recap-content {
            position: sticky;
            top: 0;
            padding-bottom: 0.8125rem;

            .items-recap-header {
                flex-direction: row;
            }
        }
    }
    .shopping-bag-list {
        //overflow: hidden!important;
        overflow-x: hidden;
        overflow-y: scroll;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;
        max-height: 25rem;

        .shopping-bag-element {
            margin-bottom: 1.9375rem;

            .shopping-bag-element-image {
                position: relative;
                z-index: 2;
                margin-right: 5%;

                img {
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }

            .shopping-bag-element-image:before {
                content: "";
                background-color: #f8f7f5;
                display: block;
                padding: 0 0 150%;
                min-width: 7.625rem;
                width: 7.625rem;
            }

            .shopping-bag-element-info {
                align-self: flex-end;
                flex-grow: 2;
                padding-bottom: 1px;
                padding-right: 5%;

                .lement-price {
                    margin-bottom: 1.25rem;
                }

                .element-quantity {
                    margin-bottom: 0;
                }

                .element-alert {
                    margin-bottom: 0;
                    margin-top: 1rem;
                }
            }
        }
    }
    .priceRecap {
        margin-top: 1.9375rem;

        .salesTaxDetails,
        .price-recap-shipping,
        .price-recap-payment {
            display: flex;
            justify-content: space-between;
        }

        .price-recap-title {
            width: auto;
            display: flex;
        }

        .price-recap-title {
            flex-shrink: 0;
        }

        .price-recap-value {
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 1;
            justify-content: flex-end;
        }

        .price-recap-total {
            margin-top: 2.8125rem;

            .total-price {
                display: flex;
                justify-content: flex-start;

                .price {
                    margin-left: auto;
                }
            }
        }
    }
    .opc-info-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 0.375rem;
        padding-left: 0;
        padding-right: 0;

        .opc-info {
            margin-bottom: 0;
            padding: 1.375rem 0 1.875rem;
            border-top: 1px solid #d8d7d5;

            .opc-info-title {
                margin: 0;
            }
        }

        .info-secure-payment {
            padding-bottom: 1.875rem;
        }
    }
}

@media (max-width: 1280px) {
    ::v-deep .agreeItemDrawer .el-drawer__header > :first-child {
        margin-top: 4rem;
        margin-left: .5rem;
    }
    ::v-deep .agreeItemDrawer .el-drawer__close-btn {
        margin-top: -4rem;
    }
    .agreeItemDrawer {
        .agreeText {
            color: #696969;
            margin: 0 2.5rem 1rem 2.5rem;
        }
    }


    .items-recap-header.shop-cart {
        display: block;
    }
    .orderPricesRecap.priceRecap.shop-cart {
        margin: 1.875rem;
        padding-bottom: 1.875rem;
        border-bottom: 1px solid #d8d7d5;

        .price-recap-list {
            display: block;
        }
    }
    .priceRecap {
        margin-top: 1.9375rem;

        .salesTaxDetails,
        .price-recap-shipping,
        .price-recap-payment {
            display: flex;
            justify-content: space-between;
        }

        .price-recap-title {
            width: auto;
            display: flex;
        }

        .price-recap-title {
            flex-shrink: 0;
        }

        .price-recap-value {
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 1;
            justify-content: flex-end;
        }

        .price-recap-total {
            margin-top: 2.8125rem;

            .total-price {
                display: flex;
                justify-content: flex-start;

                .price {
                    margin-left: auto;
                }
            }
        }
    }
    .orderItemsRecap.shop-cart {
        display: block;
        margin: 1.875rem;

        .shopping-bag-element {
            margin-bottom: 1.9375rem;

            .shopping-bag-element-image {
                position: relative;
                z-index: 2;
                margin-right: 5%;
                width: 50%;
                height: 200px;
                background-color: #f8f7f5;

                img {
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    max-height: 100%;
                }
            }

            .shopping-bag-element-image:before {
                content: "";
                display: block;
                padding: 0 0 150%;
                min-width: 7.625rem;
                width: 7.625rem;
            }

            .shopping-bag-element-info {
                align-self: flex-end;
                flex-grow: 2;
                padding-bottom: 1px;
                padding-right: 5%;
                width: 50%;

                .lement-price {
                    margin-bottom: 1.25rem;
                }

                .element-quantity {
                    margin-bottom: 0;
                }

                .element-alert {
                    margin-bottom: 0;
                    margin-top: 1rem;
                }
            }
        }

        img {
            width: 100%;
        }
    }
    .shopping-bag-list.shop-cart {
        display: block;
    }
    .chekOutBox {
        margin-top: 3.125rem;
    }
    .navigation-steps-title {
        display: none;
    }
    .opc-navigation.navigation-steps {
        display: none;
    }
    .addressEntry {
        padding: 1rem;
        margin: 0;
    }
    .address-wrapper {
        color: #888;
    }

    .opc-info.info-view-bag-care {
        display: flex;
        justify-content: space-between;
        border-bottom: none;

        .view-bag {
            border-bottom: 1px solid currentColor;
            padding-bottom: 2px;
            cursor: pointer;
        }
    }
    .opc-info-wrapper {
        padding: 0;
    }
    .opc-info {
        padding: 1rem 1.875rem;
        border-bottom: 1px solid #d8d7d5;
    }
    .price-recap-list {
        display: none;
    }
    .items-recap-header,
    .orderItemsRecap {
        display: none;
    }
    .back-button {
        background-color: #fff;
        color: #000;
        padding: 0;
        text-align: left;
        position: relative;
        margin-top: 0;
    }
    .back-icon:before {
        background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/arrow-right.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        content: "";
        padding: 0 0 100%;
        display: block;
        transform: translateY(-50%) rotate(
                180deg
        );
        left: 1.3125rem;
        right: auto;
        width: 0.5rem;
        position: absolute;
        top: 50%;
    }
}
</style>
<style lang="scss" scoped>
::v-deep .el-checkbox__label {
    color: #212121;
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    line-height: 30px;
    word-break: break-all;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #212121;
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    word-break: break-all;
}

::v-deep .el-checkbox__inner {
    border-color: #212121;
    width: 0.5rem;
    height: 0.5rem;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #212121;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #212121;
    border: none;
}

::v-deep .el-checkbox__input {
    vertical-align: top;
    line-height: 2;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    display: none;
}

::v-deep .el-checkbox {
    margin-bottom: 1rem;
    padding: 0 1rem;
}

::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
    padding: 0;
    border-bottom: 1px solid #d8d7d5;
}

::v-deep .el-form-item__label {
    padding: 0;
    line-height: 0.1rem;
}

::v-deep .el-select {
    width: 100%;
}

::v-deep .el-tabs__nav-wrap::after {
    display: none;
}

::v-deep .el-tabs__nav-scroll {
    display: flex;
}

::v-deep .el-tabs__header {
    margin: 0;
    padding: 1rem 0;
}

::v-deep .el-tabs__item {
    height: 0.8rem;
    line-height: 0;
    font-size: inherit;
}

::v-deep .el-tabs__item:hover {
    color: inherit;
}

::v-deep .el-tabs__active-bar {
    background: #212121;
    height: 1px;
}

::v-deep .el-tabs__item {
    font-weight: 400;
}

::v-deep .el-tabs__item.is-active {
    color: #212121;
}

a {
    text-decoration: none;
    color: inherit;
}

h1,
h2,
h3 {
    font-weight: 400;
    font-size: 1em;
}

.account-address-book {
    button {
        background-color: #212121;
        border-width: 0;
        color: #fff;
        display: block;
        padding: 1rem 3.125rem 0.9375rem;
        text-align: center;
        width: 100%;
        position: relative;
        margin-top: 2rem;
    }
}

.account-address-book {
    margin-top: 6rem;
}

.addressesCount .zeroAddresses {
}

.addressesCount .Addresses {
}

.addressEntry .address-wrapper .default-address {
    font-style: italic;
    margin-bottom: 1rem;
    order: 0;
    width: 100%;
}

.addressEntry {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top: 1px solid #d8d7d5;

}

.default-address {
    font-style: italic;
}

.messageInfo {
    //border-bottom: 1px solid #000;
    cursor: pointer;
}

.value {
    line-height: 1.2rem;
}

.mfp-content-flex-item {
    margin: auto;
    max-width: 100%;
    width: 100%;
}

.mfp-content-holder {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s cubic-bezier(0.22, 0.61, 0.36, 1) 0s,
    opacity 0.7s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    background: #fff;
    padding: 1.9375rem 2rem;
}

.buttonsRow {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
}

.buttonsRow .proceed,
.buttonsRow .cancel,
.buttonsRow .submit,
.buttonsRow .close,
.buttonsRow .closeBtnBottom {
    position: relative;
    background-color: #212121;
    border-width: 0;
    color: #fff;
    display: block;
    padding: 1rem 3.125rem 0.9375rem;
    text-align: center;
    width: 100%;
    order: 1;
}

.buttonsRow button,
.buttonsRow input {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
}

.buttonsRow button,
.buttonsRow input,
.fieldRow {
    margin-bottom: 2rem;
}

[type="button"],
[type="submit"],
button {
    cursor: pointer;
}

.buttonsRow .cancel,
.buttonsRow .close,
.buttonsRow .closeBtnBottom {
    background-color: #e1e0de;
    border-width: 0;
    color: #212121;
    display: block;
    padding: 1rem 3.125rem 0.9375rem;
    text-align: center;
    width: 100%;
    order: 2;
}

.delete-message {
    text-align: center;
    margin-bottom: 2rem;
}

.addressDraer {
    .mfp-container {
        display: flex;
        min-height: 100%;
        flex-direction: column;

        .inner {
            width: 100%;
            height: 100%;
            display: flex;
            position: relative;
            flex-grow: 1;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;
            overflow-x: hidden;
            overflow-y: auto;

            .mfp-content-flex-item {
                //width: 51.875rem;
                form {
                    //.Surname,.SurnameJp{
                    //  margin-bottom: 2rem;
                    //}

                    .CareOf,
                    .privacyDisclaimer {
                        margin-bottom: 2rem;
                    }

                    .buttonsRow {
                        margin-top: 2rem;

                        button {
                            margin-left: 0.3125rem;
                            margin-right: 0.3125rem;
                        }
                    }

                    .layer-title {
                        margin: 0;
                        clip: auto;
                        width: auto;
                        height: auto;
                        overflow: visible;
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 2;
                        grid-column-end: 3;
                        -ms-grid-row: 1;
                        grid-row-start: 1;
                        -ms-grid-row-span: 1;
                        grid-row-end: 2;
                    }

                    .mandatory {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 2;
                        grid-row-start: 2;
                        -ms-grid-row-span: 1;
                        grid-row-end: 3;
                    }

                    .serverSideErrors {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 2;
                        grid-row-start: 2;
                        -ms-grid-row-span: 1;
                        grid-row-end: 3;
                    }

                    .Name {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 3;
                        grid-row-start: 3;
                        -ms-grid-row-span: 1;
                        grid-row-end: 4;
                    }

                    .Surname {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 3;
                        grid-row-start: 3;
                        -ms-grid-row-span: 1;
                        grid-row-end: 4;
                    }

                    .SurnameJp {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 3;
                        grid-row-start: 3;
                        -ms-grid-row-span: 1;
                        grid-row-end: 4;
                    }

                    .Address {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 4;
                        grid-row-start: 4;
                        -ms-grid-row-span: 1;
                        grid-row-end: 5;
                    }

                    .CareOf {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 4;
                        grid-row-start: 4;
                        -ms-grid-row-span: 1;
                        grid-row-end: 5;
                    }

                    .City {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 5;
                        grid-row-start: 5;
                        -ms-grid-row-span: 1;
                        grid-row-end: 6;
                    }

                    .countryField {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 6;
                        grid-row-start: 6;
                        -ms-grid-row-span: 1;
                        grid-row-end: 7;
                    }

                    .Province {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 6;
                        grid-row-start: 6;
                        -ms-grid-row-span: 1;
                        grid-row-end: 7;
                    }

                    .Mail {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 7;
                        grid-row-start: 7;
                        -ms-grid-row-span: 1;
                        grid-row-end: 8;
                    }

                    .Phone {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 7;
                        grid-row-start: 7;
                        -ms-grid-row-span: 1;
                        grid-row-end: 8;

                        ::v-deep .el-form-item__content {
                            display: flex;
                        }
                    }

                    .Mobile {
                        -ms-grid-column: 1;
                        grid-column-start: 1;
                        -ms-grid-column-span: 1;
                        grid-column-end: 2;
                        -ms-grid-row: 8;
                        grid-row-start: 8;
                        -ms-grid-row-span: 1;
                        grid-row-end: 9;

                        ::v-deep .el-form-item__content {
                            display: flex;
                        }
                    }

                    .IsDefault {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 8;
                        grid-row-start: 8;
                        -ms-grid-row-span: 1;
                        grid-row-end: 9;
                    }

                    .IsBillingDefault {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 9;
                        grid-row-start: 9;
                        -ms-grid-row-span: 1;
                        grid-row-end: 10;
                    }

                    .privacyDisclaimer {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 10;
                        grid-row-start: 10;
                        -ms-grid-row-span: 1;
                        grid-row-end: 11;
                    }

                    .buttonsRow {
                        -ms-grid-column: 2;
                        grid-column-start: 2;
                        -ms-grid-column-span: 1;
                        grid-column-end: 3;
                        -ms-grid-row: 11;
                        grid-row-start: 11;
                        -ms-grid-row-span: 1;
                        grid-row-end: 12;
                    }

                    .privacyDisclaimer {
                        line-height: 1.1875rem;

                        a {
                            cursor: pointer;
                            border-bottom: 1px solid #212121;
                            padding-bottom: 0.2rem;
                        }
                    }

                    .submit {
                        background-color: #212121;
                        border-width: 0;
                        color: #fff;
                        display: block;
                        //padding: 1rem 3.125rem .9375rem;
                        text-align: center;
                        width: 100%;
                        order: 1;
                        margin-right: 0.3125rem;
                    }

                    .closeBtn {
                        background-color: #e1e0de;
                        border-width: 0;
                        color: #212121;
                        display: block;
                        //padding: 1rem 3.125rem .9375rem;
                        text-align: center;
                        width: 100%;
                        order: 2;
                        margin-left: 0.3125rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .buttonsRow .proceed,
    .buttonsRow .submit,
    .buttonsRow .cancel,
    .buttonsRow .close,
    .buttonsRow .closeBtnBottom {
        max-width: 18.75rem;
        padding: 0.3125rem 0.3125rem 0.375rem;
    }
    .buttonsRow {
        align-items: flex-start;
        flex-direction: row;
    }
    .account-address-book .addressList .addressEntry:last-child,
    .account-address-book .addressList .addressEntry:nth-last-child(2) {
        border-bottom: 1px solid #d8d7d5;
    }

    .addressEntry {
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #d8d7d5;
    }
    .addressEntry .address-wrapper {
        margin-bottom: 0;
        width: auto;
        max-width: 15rem;
    }
    .account-section {
        margin: 0 auto;
        max-width: 100%;
        width: 25rem;
    }
    .account-address-book {
        button {
            max-width: none;
            width: 23.4375rem !important;
            margin-left: 0;
            margin-right: 0;
            margin-top: 4rem;
            padding: 0.3125rem 0.3125rem 0.375rem;
        }
    }
    .addressDraer {
        .mfp-content-flex-item {
            margin: 0 auto;
            max-width: 100%;
            //width: 25rem;
            width: 51.875rem;

            form {
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 23.4375rem 23.4375rem;
                grid-template-columns: 23.4375rem 23.4375rem;
                -ms-grid-rows: 3.125rem 5rem 5rem 5rem 5rem 5rem 5rem 3.125rem 3.125rem 9.375rem 5rem;
                grid-template-rows: 3.125rem 5rem 5rem 5rem 5rem 5rem 5rem 3.125rem 3.125rem 9.375rem 5rem;
                grid-column-gap: 5rem;

                .buttonsRow {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;

                    button {
                        padding: 0.3125rem 0.3125rem 0.375rem;
                    }

                    button:hover {
                        cursor: pointer;
                    }

                    .submit {
                        max-width: 18.75rem;
                        margin-left: 0 !important;
                    }

                    .closeBtn {
                        max-width: 18.75rem;
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 1280px) {
    .drawer {
        height: 100%;
    }
    .uiLayer-confirmationAlert .mfp-content-flex-item,
    .uiLayer-confirmLayer .mfp-content-flex-item {
        width: 46.875rem;
    }
    .uiLayer-confirmLayer .buttonsRow {
        margin: 0 -3.125rem;
    }
    .mfp-content-holder {
        padding: 3.9375rem calc(4% + 0.8125rem);
    }
    .addressEntry {
        width: 23.4375rem;
        margin-top: 3rem;
    }
    .account-section {
        width: 51.875rem;
        margin: 0 auto;

        .account-overview-section {
            width: 23.4375rem;
        }
    }
    .smartphone-back-button {
        display: none;
    }
    .addressDraer {
        .mfp-content {
            min-height: 82vh;
            flex-grow: 0;

            form {
                .Name,
                .Address,
                .City,
                .Province,
                .Mail,
                .Mobile {
                    margin-right: 2.5rem;
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .items-recap-title {
        position: absolute;
        top: 1.5rem;
        left: 1rem;
    }
    .addressBox .addressesCount,
    .addressBox .account-section-full-width {
        margin: 0;
    }
    .delete-message {
        text-align: center;
        margin-bottom: 2rem;
    }
    .buttonsRow.modify {
        margin: 0 -3.125rem;
    }

    .addressBox {
        .main {
            display: none;
        }

        .account-address-book {
            margin-top: 3.125rem;
        }

        //.account-address-book .addressesCount, .account-address-book p, .account-section-full-width, .addressEntry .address-wrapper, .addressEntry .address-wrapper + div {
        //  margin-left: 1.1875rem;
        //  margin-right: 1.1875rem;
        //}

        .addressEntry .address-wrapper + div {
            display: flex;
            right: 0;
            flex-direction: column;
        }

        .smartphone-back-button {
            display: block;
            padding-top: 1rem;
            margin-bottom: 1rem;
            border-top: 1px solid #d8d7d5;

            a {
                margin-left: 1.1875rem;
                position: relative;

                .icon {
                    width: 0.5rem;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }

                .icon:before {
                    background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/arrow-left.svg);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    content: "";
                    padding: 0 0 100%;
                    display: block;
                }

                span:nth-child(2) {
                    padding-left: 0.9375rem;
                }
            }
        }

        .addressesCount,
        .account-section-full-width {
            margin: 0 1.1875rem;
        }

        .addressesCount {
            margin-top: 1.5rem;
        }
    }
    ::v-deep .v-modal {
        display: none !important;
        top: 3.125rem !important;
    }
    .addressBox {
        margin-top: 3.125rem;

        ::v-deep .el-drawer__wrapper {
            top: 3.125rem !important;
        }

        ::v-deep .el-drawer__close-btn {
            z-index: 33;
        }

        .layer-title {
            background-color: #fff;
            border-bottom: 1px solid #d8d7d5;
            border-top: 1px solid #d8d7d5;
            line-height: 50px;
            position: fixed;
            text-align: center;
            top: 50px;
            width: 100% !important;
            z-index: 1;
        }

        .mandatory,
        .Name,
        .Surname,
        .Address,
        .CareOf,
        .City,
        .countryField,
        .Phone,
        .Province,
        .Mail,
        .Mobile,
        .privacyDisclaimer,
        .buttonsRow {
            margin: 0 1.1875rem;
        }

        .buttonsRow {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin: 0 -3.125rem;

            button {
                padding: 1rem 3.125rem 0.9375rem;
                margin-bottom: 2rem;
            }
        }
    }
}
</style>
